<template>
  <div>
    <heads :more="showMore" :msg="title_msg"></heads>
    <div class="my_title">
      <p>满意度调查</p>
    </div>
    <div v-if="feedback" class="msg">
      <p>您的反馈和评价有助于我们为您提供更好的学习服务</p>
    </div>
    <div v-if="feedback" class="my_textarea">
      <textarea id="" v-model="fellVal" name="" cols="30" rows="10"></textarea>
    </div>
    <div class="analyticScoring">
      <p>分项评分：<span>(匿名统计，所有人不可见)</span></p>
    </div>
    <div class="main">
      <div v-for="(i,t) in fellList" :key="t" class="main_items">
        <p>{{ i.type_cn }}：</p>
        <p class="dissatisfied">不满意</p>
        <van-slider v-model="i.user_score" :min="0" :max="i.score" bar-height="4px" active-color="#ee2e2e" @change="upChange" />
        <p class="satisfied">很满意</p>
      </div>
    </div>
    <div class="my_submitBtn">
      <van-button color="#ee2e2e" type="primary" block @click="submit()">提交</van-button>
    </div>
  </div>
</template>

<script>
// import { Toast } from 'vant'
import { getUserPostSatisfaction, saveUserPostSatisfaction } from '@/api/my_user.js'
import heads from '@/compontens/heads.vue'

export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '满意度调查',
      fellVal: '',
      showMore: true,
      // 满意度进度列表
      fellList: [],
      feedback: '',
      satisfaction_id: ''

    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      getUserPostSatisfaction().then((res) => {
        if (res.errNo == 0) {
          console.log(res)
          const result = res.result || {}
          this.fellList = result.define || []
          const info = result.info || {}
          this.feedback = info.feedback
          this.fellVal = info.user_post_comment || ''
          this.satisfaction_id = info.id || ''
        }
      })
    },
    // 保存
    submit() {
      var status = this.fellList.every((item) => {
        return item.user_score == 0
      })
      if (status) {
        return this.$toast('您还未打分')
      }
      var info = {
        user_post_comment: this.fellVal,
        satisfaction_id: this.satisfaction_id,
        define: JSON.stringify(this.fellList)
      }
      saveUserPostSatisfaction(info).then((res) => {
        if (res.errNo == 0) {
          this.$toast(res.result)
        }
      })
    },
    go_back() {
      this.$router.go(-1)
    },
    onChange() {
      console.log(this.RateValue)
    },
    upChange(value) {
      this.$toast('当前值：' + value)
    }
  }
}
</script>

<style lang="scss" scoped>
.my_submitBtn {
  margin-top: 50px;
  width: 100%;
  height: 200px;
}
.dissatisfied {
  display: inline-block;
  font-size: 25px;
  color: #999999;
  position: relative;
  top: 10px;
}
.satisfied {
  display: inline-block;
  font-size: 25px;
  color: #999999;
  position: relative;
  top: 10px;
}
.analyticScoring p:nth-child(1) {
  padding-left: 30px;
  font-size: 30px;
  span {
    color: #ccc;
  }
}
.main_items {
  width: 100%;
  height: 200px;
}
.main_items p {
  padding: 43px 0 0 30px;
  font-size: 30px;
}
// .analyticScoring p:nth-child(2){
//   padding: 43px 0 0 30px;
//   font-size: 30px;
// }
.analyticScoring {
  width: 100%;
  height: 74px;
}
.my_textarea {
  width: 100%;
  height: 280px;
  textarea {
    width: 690px;
    height: 189px;
    border: 1px solid #ccc;
    margin-left: 30px;
  }
}
.Rate {
  width: 100%;
  height: 85px;
  overflow: hidden;
  p {
    display: inline-block;
    font-size: 30px;
    color: #999999;
    position: relative;
    bottom: 15px;
    padding: 0 0 0 35px;
  }
}
.my_header {
  height: 90px;
  width: 100%;
  background: #2a292d;
  display: flex;
  justify-content: space-between;
  img {
    width: 27px;
    height: 46px;
    margin: 24px 0 0 30px;
  }
}
.title {
  font-size: 30px;
  color: #fff;
  line-height: 90px;
  padding-right: 20px;
}
.my_title {
  width: 100%;
  height: 182px;
  background: url("../../static/images/my_index/tit_img.png") no-repeat;
  background-size: 100%;
  p {
    color: #ee2e2e;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    padding-top: 45px;
  }
}
.msg {
  width: 100%;
  height: 60px;
  text-align: center;
  font-size: 30px;
}
</style>

<style>
.van-rate {
  margin-left: 20px;
}
.van-slider {
  width: 58%;
  margin-left: 35px;
  display: inline-block;
}
.van-button {
  margin: 0 auto;
  width: 91%;
}
</style>
